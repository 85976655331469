// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.1) !important;
    }
    .bg-medium-#{$color} {
        background-color: rgba(($value), 0.75) !important;
    }
    .bg-strong-#{$color} {
        background-color: $value !important;
    }
    .color-#{$color} {
        color: $value !important;
    }
    .color-medium-#{$color} {
        color: rgba(($value), 0.75) !important;
    }
}
